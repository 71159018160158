import { useQuery } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';

import type { ApiError } from '@/api/types';
import Api from '@/controllers/api';
import { pick } from '@/utils';
import { uniqBy } from '@/utils/Helpers';
import type { IQueryRequest } from '@/utils/build-query-params';
import buildFullQueryParams from '@/utils/build-query-params';

import type {
  ClientsQueryParams,
  ContractorsQueryParams,
  EmployersQueryParams,
  IPoint,
  PickClients,
  PickContractors,
  PointsResult,
  PointsResultQueryParams,
} from './types';
export const useClients = <Data = PickClients>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: ClientsQueryParams;
  options?: Omit<
    UseQueryOptions<PickClients, ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) =>
  useQuery<PickClients, ApiError, Data>({
    queryKey: ['super', 'clients', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<PickClients>(
        buildFullQueryParams({
          path: '/super/clients',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: PickClients }) =>
        data.map((item) => pick(item, ['id', 'title'])),
      ),
    ...options,
  });

export const useContractors = <Data = PickContractors>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: ContractorsQueryParams;
  options?: Omit<
    UseQueryOptions<PickContractors, ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) =>
  useQuery<PickContractors, ApiError, Data>({
    queryKey: ['supervisor', 'contractors', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<PickContractors>(
        buildFullQueryParams({
          path: '/super/contractors',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: PickContractors }) => data.map((item) => item)),
    ...options,
  });

export const useEmployers = <Data = IEmployer[]>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: EmployersQueryParams;
  options?: Omit<
    UseQueryOptions<IEmployer[], ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) =>
  useQuery<IEmployer[], ApiError, Data>({
    queryKey: ['supervisor', 'employers', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<IEmployer[]>(
        buildFullQueryParams({
          path: '/employers',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: IEmployer[] }) => data),
    ...options,
  });

export const usePoints = <Data = PointsResult>({
  queryParams = { limit: 10000 },
  options,
}: {
  queryParams?: PointsResultQueryParams;
  options?: Omit<
    UseQueryOptions<PointsResult, ApiError, Data>,
    'queryKey' | 'queryFn'
  >;
}) => {
  return useQuery<PointsResult, ApiError, Data>({
    queryKey: ['supervisor', 'points', 'list', queryParams],
    queryFn: ({ signal }) =>
      Api.get<IPoint[]>(
        buildFullQueryParams({
          path: '/super/workplaces',
          queryParams: queryParams as IQueryRequest,
        }),
        {
          signal,
        },
      ).then(({ data }: { data: IPoint[] }) => {
        const workplaces = data.map(
          ({ id, title, technicalName, ...workplace }) => ({
            id,
            title: technicalName ? `${technicalName}, ${title}` : title,
            ...workplace,
          }),
        );
        const regions = uniqBy(
          data.map(({ address }) => ({
            id: address.city,
            title: address.city,
          })),
          'id',
        );

        return { workplaces, regions };
      }) as unknown as PointsResult,
    ...options,
  });
};

//TODO вынести в entities/templates? || entities/dictionaries?
export const useTemplates = <Data = ITemplates>(
  options?: Omit<
    UseQueryOptions<ITemplates, ApiError, Data>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery<ITemplates, ApiError, Data>({
    queryKey: ['templates', 'list'],
    queryFn: ({ signal }) =>
      Api.get<ITemplatesServerResponse>(`/templates?limit=10000`, {
        signal,
      }).then(({ data }: { data: ITemplatesServerResponse }) => {
        const { basicProfessions = [], customTemplates = [] } = data;
        const customData = [...basicProfessions, ...customTemplates]
          .map((template: ITemplateBaseElement) => ({
            ...template,
            payment_per_hour: template.payment_per_hour,
            id: template.custom_name,
            title: template.custom_name,
            workplace: template.workplace?._id,
            contractor: template.workplace?.contractor,
          }))
          .filter(
            (template: ITemplate) => template.is_basic || !!template.workplace,
          ) as unknown as ITemplates;
        return customData;
      }),
    ...options,
  });
