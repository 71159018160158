/* eslint-disable react/prop-types */ // TODO скрыты legacy ошибоки PropTypes в консоли
import { Button as AntButton } from 'antd';
import type { ButtonProps as AntButtonProps } from 'antd';
import { useMemo } from 'react';
import classnames from 'classnames';

import type { ButtonsColors } from '@/types/ant-types';

import css from './styles.module.scss';

interface IButtonProps extends AntButtonProps {
  style?: React.CSSProperties;
  ref?: React.LegacyRef<HTMLButtonElement | HTMLAnchorElement> | undefined;
  download?: string;
  color?: ButtonsColors;
  wideSides?: 'middle' | 'large';
  buttonSize?: 'small' | 'middle';
  icon?: React.ReactNode;
  htmlType?: 'button' | 'submit' | 'reset';
  textSize?: number;
}
function Button({
  style,
  ref,
  download,
  color = 'yellow',
  wideSides,
  type,
  size = 'middle',
  buttonSize = 'middle',
  disabled,
  hidden,
  className,
  icon,
  htmlType,
  children,
  textSize,
  ...props
}: IButtonProps) {
  const classes = useMemo(
    () => ({
      container: classnames(css.container, className, {
        [css.colorBlue]: color === 'blue',
        [css.colorLightBlue]: color === 'lightblue',
        [css.colorYellow]: color === 'yellow',
        [css.colorWhite]: color === 'white',
        [css.colorGray]: color === 'gray',
        [css.colorOrange]: color === 'orange',
        [css.colorRed]: color === 'red',
        [css.colorGreen]: color === 'green',

        [css.disabled]: disabled === true,
        [css.disabledWhite]: disabled === true && color === 'white',
        [css.disabledGray]: disabled === true && color === 'gray',

        [css.hidden]: hidden === true,
        [css.link]: type === 'link',

        [css.wideSidesMiddle]: wideSides === 'middle',
        [css.wideSidesLarge]: wideSides === 'large',

        [css.buttonSizeSmall]: buttonSize === 'small',
        [css.buttonSizeMiddle]: buttonSize === 'middle',
      }),
    }),
    [className, color, disabled, hidden, type, wideSides, buttonSize],
  );

  return (
    <AntButton
      htmlType={htmlType}
      ref={ref}
      download={download}
      color={color}
      className={classes.container}
      size={size}
      style={{ ...style, fontSize: textSize }}
      disabled={disabled}
      {...props}
    >
      {icon && icon}
      {children}
    </AntButton>
  );
}

export default Button;
