export interface IQueryRequest {
  [key: string]: string;
}
export interface IQueryMultiRequest {
  [key: string]: Array<string>;
}

function buildMultiSelectsQueryParams(
  multiSelects: IQueryMultiRequest,
): string {
  const paramsArray: string[] = [];
  Object.entries(multiSelects).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        paramsArray.push(`${key}[]=${encodeURIComponent(item)}`);
      });
    }
  });

  return paramsArray.join('&');
}

export default function buildFullQueryParams({
  path,
  queryParams,
  multiSelects,
}: {
  path: string;
  queryParams?: IQueryRequest;
  multiSelects?: IQueryMultiRequest;
}): string {
  let queryString = '';

  if (queryParams) {
    const queryParamsArray = Object.entries(queryParams)
      .filter(
        ([_, value]) => value !== undefined && value !== null && value !== '',
      )
      .map(([key, value]) => `${key}=${String(value)}`);
    queryString =
      queryParamsArray.length > 0 ? `?${queryParamsArray.join('&')}` : '';
  }

  const multiSelectsParams = buildMultiSelectsQueryParams(multiSelects || {});

  if (multiSelectsParams) {
    if (queryString === '') {
      queryString = `?${multiSelectsParams}`;
    } else {
      queryString += `&${multiSelectsParams}`;
    }
  }

  return `${path}${queryString}`;
}
